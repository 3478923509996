import { createSlice } from "@reduxjs/toolkit";

export const smartBrandSlice = createSlice({
    name: "smartBrand",
    initialState: {
        qrcodes: [{}],
        tagcodes: [{}],
        bizcards: [{}]
    },
    reducers:{
        setQrcodeData: (state, action) => {},
        setTagcodeData: (state, action) => {},
        setBizcardData: (state, action) => {},
    }
});

export default smartBrandSlice.reducer;
export const { setBizcardData, setQrcodeData, setTagcodeData } = smartBrandSlice.actions;