import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import BxTopPageHeader from 'library/bxui/BxTopPageHeader/BxTopPageHeader';
import BxCapsuleTabs from 'library/bxui/BxCapsuleTabs/BxCapsuleTabs';

const ContentsWithScrollBarLCWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: ${ ({ height }) => height }vh;
// min-height: 300px;
position: relative;
// border-radius: 0.8rem;

z-index: 0;

// background: pink;

-webkit-transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
-moz-transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
-ms-transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

.sectionHeader {
    margin: 0 auto;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    margin-bottom: 0.5rem;

    h2{
        margin: 0 auto;
        width: 100%;
        height: auto;
        display: block;
        position: relative;
        line-height: 1.2;
        padding: 0;
        margin-bottom: 5px;
        text-transform: capitalize;
        font-family: var(--bx-font-family-primary-lato-light);
        font-size: var(--bx-font-size-xx-large);
        color: #bbb;
    }
    p {
        margin: 0 auto;
        width: 100%;
        height: auto;
        display: block;
        position: relative;
        line-height: 1.2;
        padding: 0;
        letter-spacing: 0;
        font-family: var(--bx-font-family-primary-lato-bold);
        font-size: var(--bx-font-size-regular);
        color: #aaa;
    }
    @media all and (min-width: )
}

.contents-with-scroll-bar{
    margin: 0 auto;
    width: 100%;
    height: ${ ({ height, offsetHeight }) => (height - offsetHeight) }vh;
    position: relative;
    overflow: hidden;
}


// :hover {
//     -webkit-box-shadow: 0px 25px 35px 2px rgba(0,0,0,0.05);
//     -moz-box-shadow:    0px 25px 35px 2px rgba(0,0,0,0.05);
//     box-shadow:         0px 25px 35px 2px rgba(0,0,0,0.05); 
// }

`;

function ContentsWithScrollBarLC({ children, height = 40, offsetHeight=5, headerWidth, headerPlacement, title, activeTabPage = 0, onTabChange, sectionTitle, sectionDescription, triggerOpen = false, triggerClose = false, settings }) {
    

    const onOpenContentScroll = () => {

        if ($('.contents-with-scroll-bar')) {
            setTimeout(() => {                
                $('.contents-with-scroll-bar').mCustomScrollbar({
                    theme: settings?.theme || "minimal-dark",
                    axis:"y",
                });
            }, 10);
        }
        
    };
    const onDestroyContentScroll = () => {
        if ($('.contents-with-scroll-bar')) {
            $('.contents-with-scroll-bar').mCustomScrollbar("destroy");
        }
        
    };

    useEffect(() => {
        onOpenContentScroll();

        return(() => {
            onDestroyContentScroll();
        })
    }, []);

    function handleTabChange( ind ) {
        setActiveTabIndex(ind);

        // run function to change the tab content
        if( !(onTabChange instanceof Function) ) return;
        onTabChange( ind );
    }


    // if user passes in tabbed header titles, here's the function to run when the tab changes
    const [activeTabIndex, setActiveTabIndex] = useState( activeTabPage );

  return (
    <ContentsWithScrollBarLCWrapper height={ height } offsetHeight={ offsetHeight }>
        <div className="sectionHeader">
            { sectionTitle && <h2>{ sectionTitle }</h2> }
            { sectionDescription && <p>{ sectionDescription }</p> }
        </div>
        {
            ( title && typeof(title) === 'string' ) && <BxTopPageHeader title={ title } />
        }
        {
            ( title && Array.isArray( title ) ) && <BxCapsuleTabs tabs={ Array.isArray(title) ? [...title] : [] } activeTab = { activeTabIndex } onChangeTab={ (newInd) => handleTabChange(newInd) } width={ headerWidth } placement={ headerPlacement } />
        }
        <div className='contents-with-scroll-bar'>
            { children }
        </div>
    </ContentsWithScrollBarLCWrapper>
  )
}

export default ContentsWithScrollBarLC;