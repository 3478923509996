import { useState } from "react";
import styled from "styled-components";

import HeroSliderV1 from "library/heroslider/HeroSliderV1";

const PageWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: auto;
display: flex;
position: relative;

.hero-area {
    margin: 0 auto;
    width: 100%;
    height: 80vh;
    display: flex;
    position: relative;
}
`;

const Welcome = () => {

    const [ slideData, setSlideData ] = useState([
        {
        image: 'hero-v1/smartest-brand-1-1.jpg',  
        title: "Be the smartest brand in the room!",
        description: "One Smart card to share invoices, receipts, quotes, business cards, letterheads, digital office, flyers, banners, portfolios, resumes/CVs, corporate profiles, brand books, powerpoint presentations, website, & social media pages." 
        },
        {
        image: 'hero-v1/magic-touch-1-1.jpg',
        title: "Add the Magic touch to your Brand!",
        description: "Share your business assets and info, the fastest way, with just a tap or scan, in meetings and conferences! Subscribe to our monthly Smart Brand bundle and get your Xpo Smart Card, Xpo Smart tag, Xpo Qrcode and many smart merchandises."
        },
        {
        image: 'hero-v1/get-started-cover-1.jpg',
        title: "Add even more awesomeness to your Brand!",
        description: "Shop for smart merchandise in our Smart brand shop and stay far ahead of your competition! Buy smart wrist bands, sport bands, table top souvenirs, badges, phone cases, phone cards, rings, button tags, & stickers."
        },
        
      ]);
    

    return (    
            <PageWrapper>
                <div className="hero-area">
                <HeroSliderV1 data={ [ ...slideData ] } maxTransSlides={ 5 } />
                </div>
                <div className="page-content__main"></div>
            </PageWrapper>
        

     );
}
 
export default Welcome;