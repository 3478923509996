import React, { useEffect } from 'react';
import styled from 'styled-components';
import BxAppButton from 'library/bxui/BxAppButton/BxAppButton';
import ContentsWithScrollBarLC from 'layouts/lcs/ContentsWithScrollBarLC';

import { AppConfig } from "services/AppConfigService.ts";

const ControlCenterScreenAppsWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: auto;
display: flex;
flex-flow: row wrap;
justify-content: space-around;
align-items: flex-start;
gap: 20px;

`;

function ControlCenterScreenApps() {

    const appUrls = {
        development: {
            main: "http://localhost:3000",
            brands: "http://localhost:3001",
            "smart-brands": "http://localhost:3002",
            explore: "http://localhost:3003",
            stories: "http://localhost:3004",
            about: "http://localhost:3005",
        },
        production: {
            main: "https://biznesxpo.com",
            brands: "https://brands.biznesxpo.com",
            "smart-brands": "https://smartbrand.biznesxpo.com",
            explore: "https://explore.biznesxpo.com",
            stories: "https://stories.biznesxpo.com",
            about: "https://about.biznesxpo.com",
        }
    }

    // onmount
    useEffect(() => {
        // console.log('ENV: ', process.env.NODE_ENV);
    }, []);


  return (
    <ControlCenterScreenAppsWrapper>
        { 
            AppConfig.appName !== "main" &&
            <BxAppButton text="main site" icon="home_fill_1" url={ appUrls[ process.env.NODE_ENV ]?.main } />
        }
        { 
            AppConfig.appName !== "brands" &&
            <BxAppButton text="brands" icon="shop_fill_1" url={ appUrls[ process.env.NODE_ENV ]?.brands } />
        }
        { 
            AppConfig.appName !== "smart-brands" &&
            <BxAppButton text="smart brand" icon="smart_brand" url={ appUrls[ process.env.NODE_ENV ]["smart-brands"] } />
        }
        { 
            AppConfig.appName !== "explore" &&
            <BxAppButton text="explore" icon="explore" url={ appUrls[ process.env.NODE_ENV ]?.explore } />
        }
        { 
            AppConfig.appName !== "stories" &&
            <BxAppButton text="stories" icon="stories_2" url={ appUrls[ process.env.NODE_ENV ]?.stories } />
        }
        { 
            AppConfig.appName !== "about" &&
            <BxAppButton text="about" icon="brandinfo" url={ appUrls[ process.env.NODE_ENV ]?.about } />
        }
        
        
        
        
        {/*<BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" />
        <BxAppButton text="smart brand" icon="" /> */}
    </ControlCenterScreenAppsWrapper>
  )
}

export default ControlCenterScreenApps;