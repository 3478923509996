import { configureStore } from "@reduxjs/toolkit";

//////////// Reducers //////////
import controlCenterReducer from "./controlCenterSlice";
import appAnalyticsReducer from "./appAnalyticsSlice";
import smartbrandReducer from "./smartbrandSlice";
import appScreenReducer from "./appScreenSlice";
import storeReducer from "./storeSlice";
import userReducer from "./userSlice"; 




export default configureStore({
    reducer: {
      controlCenter: controlCenterReducer,
      appAnalytics: appAnalyticsReducer,
      smartBrand: smartbrandReducer,
      appScreen: appScreenReducer,
      store: storeReducer,
      user: userReducer,
    }
});