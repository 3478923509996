import { useDispatch } from "react-redux";


const UserHome = () => {
    const dispatch = useDispatch();
    return ( 
        <>
        <h1>UserHome...</h1>
        </>
     );
}
 
export default UserHome;