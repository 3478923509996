import { useSelector } from "react-redux";

import Welcome from "./public/Welcome";
import UserHome from "./auth/UserHome";

import useLocalStorage from "hooks/useLocalStorage";


const AppHome = () => {
    // const [lsUserStatus, setLSUserStatus] = useLocalStorage('lsuser');
  // useSelector( state => state.user )

  const { isLoggedIn } = useSelector( state => state.user );

    return ( 
        <>
        
            { ( !isLoggedIn ) ? 
                <Welcome />
            :
                <UserHome />
            }

        </>
     );
}
 
export default AppHome;