const { createSlice } = require("@reduxjs/toolkit");

const controlCenterSlice = createSlice({
    name: "controlCenter",
    initialState: {   
        isVisible: false,
        activeScreen: 'apps', // apps, other
    }, 
    reducers: {
        updateIsVisible: ( state, action ) => {
            // console.log('UpdateIsVisible control center...');
            state.isVisible = action.payload;
        },
        updateActiveScreen: ( state, action ) => {
            // console.log('UpdateActiveScreen control center...');
            state.activeScreen = action.payload;
        },
    }
});

// export reducer as default
export default controlCenterSlice.reducer;

// export actions
export const { updateIsVisible, updateActiveScreen } = controlCenterSlice.actions;