import SvgIcons from 'library/svgs/SvgIcons';
import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components'; 

const BxAppButtonWrapper = styled.button`
margin: 0 auto;
width: clamp(100px, 30vw, 200px);
height: clamp(100px, 30vw, 200px);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: 1px solid #ffffff99;
border-radius: 30px;
position: relative;

overflow: hidden;
background: #ffffffbb;

gap: 10px;

-webkit-box-shadow: 0px 25px 25px 2px rgba(0,0,0,0.1);
-moz-box-shadow:    0px 25px 25px 2px rgba(0,0,0,0.1);
box-shadow:         0px 25px 25px 2px rgba(0,0,0,0.1); 

webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
-moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
-ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;

.icon {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // background: pink;
    position: relative;
    flex-basis: 70%;
    width: 100%;
    // flex: 1;
    svg {
        // margin: 0 auto;
        // display: block;
        // position: relative;
        // display: block;
        fill: #888;
        width: min(70px, 90%);
        height: auto;

        webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
        -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
        -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
        transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
    }
}
.app-title {
    color: #555;
    text-transform: capitalize;
    // background: skyblue;
    position: relative;
    flex-basis: 30%;
    width: 100%;
    // flex: 1;

    webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
    -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
    -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
}

:hover{
    border: 1px solid #ffffffff;
    background: #ffffffff;

    .icon {
        svg {
            fill: #111;
        }
    }

    .app-title {
        color: #111;
    }
}
`;

function BxButtonInner({ text="app title", icon, url }) {
    return (
        <BxAppButtonWrapper>
            <span className='icon'><SvgIcons name={ icon } /></span>
            <span className='app-title'>{ text }</span>
        </BxAppButtonWrapper>
    );
}

function BxAppButton( props ) {
  return (
    <>
    { 
        props.url ?
        <Link to={ props.url }>
            <BxButtonInner {...props} />
        </Link>
        :
        <BxButtonInner {...props} />
    }
    </>
  )
}

export default BxAppButton;