const { createSlice } = require("@reduxjs/toolkit");

const appAnalyticsSlice = createSlice({
    name: "appAnalytics",
    initialState: {   
        user: {}
    }, 
    reducers: {
        updateUserData: ( state, action ) => {
            state.user = action.payload;
        }
    }
});

// export reducer as default
export default appAnalyticsSlice.reducer;

// export actions
export const { updateUserData } = appAnalyticsSlice.actions;