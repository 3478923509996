import { createSlice } from "@reduxjs/toolkit";

export const storeSlice = createSlice({
    name: "store",
    initialState: {
        products: {},
        cartItems: [],
    },
    reducers: {
        addProductToCart: ( state, action ) => {},
        removeFromCart: ( state, action ) => {},
    }
});

export default storeSlice.reducer;
export const { addProductToCart, removeFromCart } = storeSlice.actions;

