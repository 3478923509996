
import './App.scss';
import MainHeader from './modules/common/main-header/MainHeader';
import MainFooter from './modules/common/main-footer/MainFooter';
import AllRoutes from './modules/common/all-routes/AllRoutes';
import MainAppBackground from './library/bxui/BxBg/MainAppBackground';

import { useSelector, useDispatch } from 'react-redux';
import ControlCenter from 'control-center/ControlCenter';

function App() {

  const { isLoggedIn } = useSelector( state => state.user );

  return (
    <>
      <ControlCenter />

        <section id="app-holder">
          <MainHeader isUserLoggedIn={ isLoggedIn } />

          <main id="main-app">  
            <AllRoutes />
          </main>

          <MainFooter />
        </section>
        
      <MainAppBackground />

    </>
  );
}

export default App;
