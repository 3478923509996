import React from 'react';
import styled from 'styled-components';

const TopPageHeaderWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: 40px;
display: flex;
position: relative;
color: var(--bx-color-black-5);
font-size: clamp(12px, 4vw, 16px);
font-family: var(--bx-font-family-primary-lato-bold);
line-height: 0.9;
border-bottom: 0.5px solid #d7d7d7;
text-transform: capitalize;
padding: 1rem 0 0.5rem 0;

@media all and (min-width: 768px) {
  
}
`;
function BxTopPageHeader({ title }) {
  return (
    <TopPageHeaderWrapper>
        {
            title && <span>{ title }</span>
        }        
    </TopPageHeaderWrapper>
  )
}

export default BxTopPageHeader