import SvgIcons from 'library/svgs/SvgIcons';
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import styled from 'styled-components';

const SimpleButtonWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    webkit-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    -moz-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    -ms-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    transition: all 0.4s (0.16, 1, 0.3, 1);

    background: var(--color-white);
    border: 0.5px solid var(--bx-color-white);
    border-radius: 20px;
    width: auto;
    position: relative;
    padding: 0.5rem 1rem;
    font-size: clamp(0.8rem, 1vw, 1.1rem);
    font-weight: bold;
    color: var(--bx-color-black-5);
    line-height: 1;
    font-family: var(--bx-font-family-primary-lato-light);

    -webkit-box-shadow: 0px 5px 25px 2px rgba(0,0,0,0);
    -moz-box-shadow:    0px 5px 25px 2px rgba(0,0,0,0);
    box-shadow:         0px 5px 25px 2px rgba(0,0,0,0); 

    svg{ 
        fill: var(--bx-color-white-3); 

        webkit-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
        -moz-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
        -ms-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
        transition: all 0.4s (0.16, 1, 0.3, 1);
    }


    .text { text-transform: capitalize; }
    .icon {
      flex-basis: clamp(15px, 10vw, 20px);
      width: clamp(20px, 10vw, 30px);
      height: 20px;
      svg {
        margin: 0 auto;
        display: block;
        width: 100%;
      }
    }



:hover {
    border: 0.5px solid var(--bx-color-white-2);
    color: var(--bx-color-black-1);

    webkit-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    -moz-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    -ms-transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    transition: all 0.4s (0.16, 1, 0.3, 1);

    -webkit-box-shadow: 0px 5px 25px 2px rgba(0,0,0,0.1);
    -moz-box-shadow:    0px 5px 25px 2px rgba(0,0,0,0.1);
    box-shadow:         0px 5px 25px 2px rgba(0,0,0,0.1); 
    svg{ fill: var(--bx-color-black-1); }
}
`;




const SimpleButtonV1 = ( props ) => {
  
  return (
    <>
    { props.url && 
      <Link to={props.url}>
        <SimpleButtonHtml { ...props } />
      </Link>
    }
    { !props.url && <SimpleButtonHtml { ...props } />}
    </>
  )
}

export default React.memo(SimpleButtonV1);


const SimpleButtonHtml = ({ text, icon, customClass, onClick }) => {

  const handleOnClick = (ev) => {
    // ev.stopPropagation();
    if (!onClick || !(onClick instanceof Function )) return null;
    onClick();
  }

  return(
    <SimpleButtonWrapper onClick={ (e) => handleOnClick(e) } className={ customClass ? customClass : '' }>
      { icon &&
        <span className="icon"><SvgIcons name={ icon } /></span>
      }
      {
        text &&
        <span className="text">{ text }</span>
      }
    </SimpleButtonWrapper>
  );
}
