import React, { useState } from 'react';
import { useLocation, matchRoutes, useParams, useSearchParams } from "react-router-dom";


export function useGetPathName() {
  let location = useLocation();
  return location.pathname;
}

export function useGetPathNameArr() {
    let location = useLocation();
    const pathArr = getPathNameArr( location.pathname );
  return pathArr.filter((v) => v.trim() !== "" );
}

export function usePathParams() {
  const params = useParams();
  // console.log("Params: ", params);
  return params;
}
export function usePathQueryParams() {
  const queryParams = useSearchParams();
  // console.log("Query Params: ", queryParams);
  return queryParams;
}

// convert a path name to array 
const getPathNameArr = ( pathname ) => {
  let pathArr = pathname.split('/'); 
  pathArr.shift();
  return pathArr;
}



