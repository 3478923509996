import { createSlice } from "@reduxjs/toolkit";
import { useLocation, matchRoutes, useParams } from "react-router-dom";

/**
 * 
 * < 320px .... Extra Small Screens
 * 320px - 480px .... Mobile devices
 * 481px - 768px .... iPads & tablets
 * 769px - 1024px .... Small Screens or Laptops
 * 1025px - 1200px .... Large Screens or Desktops
 * >= 1201px .... Extra Large Screens
 * 
 */

export const appScreenSlice = createSlice({
    name: "appScreen",
    initialState: {
        curPathName: "",
        curPathNameArr: [],
        mainSubMenuPages: ['playground', 'explore', 'stories', 'smart-brand'],
        breakpoints: [320, 480, 768, 1024, 1200],
        breakpointsReversed: [1200, 1024, 768, 480, 320],
        currentBreakpoint: -1,
        pageScrollY: window.scrollY,
        isDarkMode: false,
        isOnline: false,
    },
    reducers: {
        setAppCurrentBreakpoint: (state, action) => {

            // state.breakpoint is null
            if(!state.breakpoints) return;
            // payload is null
            if(!action.payload || isNaN( action.payload )) return;

            // let breakPointArrLength = state.breakpoints.length;

            // console.log('Breakpoints: ', action.payload, breakPointArrLength);

            if(action.payload >= state.breakpointsReversed[ 0 ]) {
                // set to largest breakpoint
                state.currentBreakpoint = state.breakpointsReversed[ 0 ];
            } else if(action.payload <= state.breakpoints[ 0 ]){
                // set to lowest breakpoint
                state.currentBreakpoint = state.breakpoints[ 0 ];
            }
            
            else {
                let closestHighestVal = state.breakpointsReversed.reduce( (finalV, v, i) => {
                    if(v > action.payload && v < finalV) { finalV = v; }
                    // console.log(state.currentBreakpoint, v, action.payload);
                    // console.log('Final Value: ', finalV, v, action.payload);
                    return finalV;
                }, Number.MAX_VALUE);

                // console.log('Closest Highest: ', closestHighestVal);

                state.currentBreakpoint = closestHighestVal;
            }

            // console.log('CurrentBreakpoint: ', state.currentBreakpoint);

        },
        setAppConnStatus: (state, action) => {
            state.isOnline = action.payload;
        },
        setIsDarkMode: (state, action) => {
            state.isDarkMode = action.payload;
        },
        setPageScrollY: (state, action) => {
            state.pageScrollY = action.payload;
        },
        setCurPathName: (state, action) => {
            state.curPathName = action.payload;
        },
        setCurPathNameArr: (state, action) => {
            state.curPathNameArr = [...action.payload];
        },
    }
});

// export reducer as default
export default appScreenSlice.reducer;

// export actions
export const { setAppCurrentBreakpoint, setAppConnStatus, setIsDarkMode, setPageScrollY, setCurPathName, setCurPathNameArr } = appScreenSlice.actions;