// generate unique id
export const genUUID = ( num = 12 ) => Math.random().toString(36).substring(2, num); 

// recursively step through a nested object to get value
export const getValueNestedObj = ( obj: any, path: any ) => path.split('/').reduce( ( o: any, p:any ) => o[p], obj );

// convert a path name to array 
export const getPathNameArr = ( pathname: any ) => {
    let pathArr = pathname.split('/'); 
    pathArr.shift();
    return pathArr;
}

// check if button is active
export const isBtnActive = ( btnPagePathArr, curPath ) => {
    if(!btnPagePathArr) return;
    if(!curPath) return;
    let pathArr = getPathNameArr(curPath);

    const active = btnPagePathArr.every((v) => pathArr.includes(v) ) ? true : false;

    // console.log(active, btnPagePathArr, pathArr );
    
    return active;
}

// sum of all the numbers in an array
export const sumNumArray = ( arr: Array<number> ) => {
    let total = arr.reduce((t: number, c: number) => (t + c), 0);
    return total;
}


// function to shorten text, add elipsis if too long, and capitalize first character
export const shortenTxt = (text: string, len = 10) => {

    if(!text) return;

    text = text.trim();

    let shortenedTxt = ( text.length > len) ? `${text.slice(0, len)}...` : text;
    
    if( shortenedTxt.length > 0 ) {
        // capitalize the first character
        shortenedTxt = shortenedTxt.charAt(0).toUpperCase() + shortenedTxt.slice(1);
    }

    return shortenedTxt;

}




// function to generate random uuid
export const genUUID2 = () => Date.now().toString(36) + Math.random().toString(36).substring(2).substring(5, 12);

// function to debounce

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait = 300) => {
    let timeout;
  
    // This is the function that is returned and will be executed many times
    // We spread (...args) to capture any number of parameters we want to pass
    return function executedFunction(...args) {
  
      // The callback function to be executed after 
      // the debounce time has elapsed
      const later = () => {
        // null timeout to indicate the debounce ended
        timeout = null;
        
        // Execute the callback
        func(...args);
      };
      // This will reset the waiting every function execution.
      // This is the step that prevents the function from
      // being executed because it will never reach the 
      // inside of the previous setTimeout  
      clearTimeout(timeout);
      
      // Restart the debounce waiting period.
      // setTimeout returns a truthy value (it differs in web vs Node)
      timeout = setTimeout(later, wait);
    };
  };


  /// function to test if variable is an object
  export function isObject (item) {
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
  }


// Clamp number between two values
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max); 


// function to simulate a delay
export const wait = (duration: number) => new Promise(resolve => setTimeout(resolve, duration));