import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { updateIsVisible } from 'redux/controlCenterSlice';
import ControlCenterScreenApps from './sections/ControlCenterScreenApps';
import ControlCenterScreenOther from './sections/ControlCenterScreenOther';
import SimpleButtonV1 from 'library/bxui/SimpleButtonV1/SimpleButtonV1';
import SvgIcons from 'library/svgs/SvgIcons';

const ControlCenterWrapper = styled.section`
margin: 0 auto;
width: 100%;
height: 100vh;
position: fixed;
top: 0; left: 0;



pointer-events: none;

display: block;

// webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
// -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
// -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
// transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;

z-index: 9999999;

.app-control-center {
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0; left: 0;
    // background: rgba(255,255,255, 0.2);
    background: rgba(0,0,0, 0.1);

    pointer-events: auto;
    z-index: 0;

    backdrop-filter: blur(30px);
    -moz-backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: stretch;

    gap: 20px;
    padding: 15px; 

    flex-direction: column;

    // transform: scale(2);
    // opacity: 0;

    // webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
    // -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
    // -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
    // transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;

    .header {
        display: flex;
        justify-content: space-between;
        width: min(90%, 800px);
        flex-basis: 10%;
        border-radius: 30px;
        // background: #ffffff22;
        // border: 0.5px solid #ccc5;

        .left {
            flex-basis: 20%;
            font-family: var(--bx-font-family-primary-lato-bold);
            font-size: var( --bx-font-size-xx-large );
        }
        .center {
            flex-basis: 60%;
            display: flex;
            justify-content: center; 
            align-items: center;

            #control-center__search {
                width: 100%;
                background: #fff4;
                border-radius: 30px;
                overflow: hidden;
                display: flex;
                gap: 5px;

                webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;

                

                label {
                    flex-basis: 50px;
                    webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    svg{ 
                        width: 100; height: auto; 
                        fill: #fff8;
                        webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                        -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                        -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                        transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    }
                }
                input {
                    border-radius: 30px;
                    flex-basis: calc(100% - 50px);
                    background: transparent;
                    webkit-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    -moz-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    -ms-transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
                }
            }
            #control-center__search:hover {
                background: #fff;
                label {
                    svg{ 
                        fill: #222;
                    }
                }
                input {
                    background: #fff;
                }
            }
        }
        .right {
            flex-basis: 20%;
            display: flex;
            justify-content: flex-end;

            button{
                background: transparent;
                .icon { 
                }
                svg: { width: 50px !important; fill: #fff6 !important; }
            }
            button:hover {
                .icon { 
                }
                svg: { fill: #fffd !important; }
            }
        }
    }

    .contents {
        width: min(90%, 800px);
        flex-basis: 80%;
        // border: 1px solid #ffffff44;
        border-radius: 30px;

        background: #ffffff44;

        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        // -webkit-box-shadow: 0px 45px 45px 2px rgba(0,0,0,0.1);
        // -moz-box-shadow:    0px 45px 45px 2px rgba(0,0,0,0.1);
        // box-shadow:         0px 45px 45px 2px rgba(0,0,0,0.1); 
    }

    .footer {
        display: flex;
        justify-content: space-between;
        width: min(90%, 800px);
        flex-basis: 10%;
        // background: #ffffff22;
        // border: 0.5px solid #ccc5;
        border-radius: 30px;

        .left {
            flex-basis: 20%;
            // font-family: var(--bx-font-family-primary-lato-bold);
            font-size: var( --bx-font-size-small );
            line-height: 1.3;
        }
        .center {
            flex-basis: 60%;
            display: flex;
            justify-content: center;
        }
        .right {
            flex-basis: 20%;
            display: flex;
            justify-content: flex-end;
            button{
                color: #fff9;
                background: transparent;
                .icon { 
                    svg: { width: 50px; fill: #fff6; }
                }
            }
            button:hover {
                color: #fff;
                .icon { 
                    svg: { fill: #fffd; }
                }
            }
        }
    }
}

.app-control-center__bg {
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0; left: 0;
    // background: rgba(255,255,255, 0.2);
    background: rgba(0,0,0, 0.2);

    pointer-events: auto;
    z-index: 0;

    display: block;

    backdrop-filter: blur(30px);
    -moz-backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);

}

`;

function ControlCenter() {

    const dispatch = useDispatch();
    const { isVisible, activeScreen } = useSelector( state => state.controlCenter );

    const [ show, setShow ] = useState(false);
    useEffect(() => {
        // test show and hide control center
        // setTimeout(() => {
        //     setShow(true);

        //     setTimeout(() => {
        //         setShow(false);
        //     }, 5000);
        // }, 3000);
    }, []);

    // listen to isVisible
    useEffect(() => {
        setShow( isVisible );
    }, [isVisible] );

//     function maxZIndex() {

//         return Array.from(document.querySelectorAll('body *'))
//               .map(a => parseFloat(window.getComputedStyle(a).zIndex))
//               .filter(a => !isNaN(a))
//               .sort()
//               .pop();
//    }


// function to close control center
const handleCloseControlCenter = (e) => {

    if (e.target.className === "app-control-center") dispatch( updateIsVisible( false ));
    
    // if (!e) e = window.event;

    // //IE9 & Other Browsers
    // if (e.stopPropagation) {
    //     e.stopPropagation();
    // }
    // //IE8 and Lower
    // else {
    //   e.cancelBubble = true;
    // }

    // console.log( "Event: ", e.target, typeof( e.target ) );

    
}


  return (
    <ControlCenterWrapper show={ show.toString() }>
    <AnimatePresence>
        { 
        show && (
        <>
            <motion.div
                key="app-control"
                transition={{
                    ease: [0.16, 1, 0.1, 1],
                    duration: 1.5,
                }}
                initial={{ opacity: 0, scale: 2 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 2 }} 
                className='app-control-center' 
                onClick={ ( e ) => handleCloseControlCenter( e ) }              
            >
                <div className='header'>
                    <div className="left">Apps</div>
                    <div className="center">
                        <form id="control-center__search">
                            <label className="search-label" htmlFor="search">
                                <SvgIcons name="search_outline" />
                            </label>
                            <input id="search" type="text" placeholder="Search BiznesXpo apps" />
                            <input className="visually-hidden" type="button" value="Search" />
                        </form>
                    </div>
                    <div className="right"><SimpleButtonV1 icon="settings" /></div>
                </div>
                <div className='contents'>
                    {
                        (activeScreen === "apps") && <ControlCenterScreenApps />
                    }
                    {
                        (activeScreen === "other") && <ControlCenterScreenOther />
                    }
                </div>
                <div className='footer'>
                    <div className="left">BiznesXpo Widgets © 2023. All Rights Reserved. BiznesXpo Inc.</div>
                    <div className="center"></div>
                    <div className="right"><SimpleButtonV1 text="close" /></div>
                </div>
            </motion.div>
        </>
        )}
            
    </AnimatePresence>
    </ControlCenterWrapper>
  )
}

export default ControlCenter;