import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice( {
    name: "user",
    initialState: {
        uuid: JSON.parse(window.localStorage.getItem('user_uuid')) ?? {},
        isLoggedIn: JSON.parse(window.localStorage.getItem('lsuser')) ?? false,
        general: JSON.parse(window.localStorage.getItem('lsusergeninfo')) ?? {},
    },
    reducers: {
        loginUser: ( state ) => {
            window.localStorage.setItem('lsuser', 'true');
            state.isLoggedIn = true;
        },
        logoutUser: ( state ) => {
            window.localStorage.setItem('lsuser', 'false');
            state.isLoggedIn = false;
        },
        setUserGenInfo: ( state, action ) => {
            let uGenData = JSON.stringify( action.payload );
            window.localStorage.setItem( 'lsusergeninfo', uGenData );
            state.general =action.payload;
        },
        setUserUUID: ( state, action ) => {
            window.localStorage.setItem( 'user_uuid', action.payload );
            state.uuid = action.payload;
        }
    }
} );

export default userSlice.reducer;
export const { loginUser, logoutUser } = userSlice.actions;