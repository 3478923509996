export const AppConfig = {
    appName: 'smart-brands',
    authType: "bx", // bx, clerk  
}



// class AppConfigService {

//     static instance = null;

//     static authType = "bx"; // bx, clerk

//     constructor() {
//         if( AppConfigService.instance === null ) {
//             AppConfigService.instance = this;
//         }

//         return AppConfigService.instance;        
//     }

//     static getInstance() {
//         return this.instance;
//     }
// }


// const AppConfig = new AppConfigService();
// Object.freeze( AppConfig );
// export default AppConfig;
