import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { shortenTxt } from 'utils/general.ts';

const BxCapsuleTabsWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: auto;
display: flex;
position: relative;

margin-top: 30px;

padding: 0;


.tabs-list {
    // margin: 0 auto;
    margin: 0;
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: ${ ({ placement }) => placement ? placement : 'space-between' };
    flex-wrap: wrap;

    position: relative;

    border: 1px solid #d7d7d7;

    border-radius: 20px;
    overflow: hidden;

    margin-top: 10px;

    background: #fff;

    -webkit-box-shadow: 0px 5px 45px 2px rgba(0,0,0,0.1);
    -moz-box-shadow:    0px 5px 45px 2px rgba(0,0,0,0.1);
    box-shadow:         0px 5px 45px 2px rgba(0,0,0,0.1); 

    // gap: 1rem;

    li {
        ${ ({ tabs, width }) => {
            let headerWidth = (width && window.innerWidth >= 768) ? width : 100;
            
            if ( tabs ) {
                let v = headerWidth / tabs.length;
                return `
                flex-basis: ${v}%;
                min-width: ${v}%;
                background: #fff;
                `;
            }
        }}
        border-right: 1px solid #d7d7d7;
        font-size: var(--bx-font-size-regular-0);
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        a {
            color: #aaa;
            position: relative;
            line-height: 0.95;
            padding: 5px 10px;
            span {
                
            }
        }

    }

    li:hover {
        background: #f7f7f7;
        a{
            color: #222
        }
    }

    li.current {
        background: #333;
        a{
            color: #f7f7f7;
        }
    }
    li.current:hover {
        background: #111;
        a{
            color: #fff;
        }
    }

    li:last-of-type {
        border-right: none;
    }

    
}
`;

function BxCapsuleTabs({ tabs=[], activeTab = 0, onChangeTab, width, placement }) {

    // const [activeTab, setActiveTab] = useState( 0 );

    // function to change the active tab
    function changeTab( newInd ) {
        // if onChangeTab is not a function
        if( !(onChangeTab instanceof Function) ) return;

        onChangeTab( newInd );
    }

  return (
    <BxCapsuleTabsWrapper className='tabbed-content' tabs={tabs} width={ width } placement={ placement }>
        
        <ul className="tabs-list">
            {
                tabs.map((v,i) => {
                    let slugified_name = v.replace(/\s+/g, '-');
                    let selected = i === activeTab ? true : false;
                    return (<li key={i} className={ i === activeTab ? "current" : '' } onClick={ () => changeTab(i)}>
                            <a aria-controls={ slugified_name }  aria-selected={ selected.toString() } href={ `#${ slugified_name }` } id={ `tab-${ slugified_name }` } role='tab' tabIndex={ selected ? '' : '-1' } className={ `tab ${ selected ? 'active' : '' }` } title={ v }><span>{ shortenTxt(v, 15) }</span></a>
                        </li>)
                })
            }
        </ul>

    </BxCapsuleTabsWrapper>
  )
}

export default React.memo(BxCapsuleTabs);