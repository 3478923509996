import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BxLogoH } from "assets/svgs/xpo-smartbrand.svg";


import { Link } from 'react-router-dom';
import SvgIcons from 'library/svgs/SvgIcons';
import { IoIosArrowDown } from "react-icons/io";

import { useGetPathName, useGetPathNameArr } from 'hooks/useRouteVars';

import { useSelector, useDispatch } from 'react-redux';
import { updateIsVisible, updateActiveScreen } from 'redux/controlCenterSlice'; 

const MainHeaderWrapper = styled.nav`
margin: 0 auto;
width: 100%;
height: 80px;
display: block;
position: fixed;
// background: tan;

.container {
  margin: 0 auto;
  width: 95%;
  height: auto;
  display: flex;
  position: relative;
  // background: pink;
  justify-content: space-between;
  margin-top: 20px;
  gap: 0.5rem;

  .main-logo {
    flex-basis: clamp(150px, 25vw, 200px);
    height: auto;
    display: block;
    position: relative;
    // background: pink;
    overflow: hidden;
    
    svg{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      // background: blue;
      z-index: 0;
    } 
  }
  .main-menu {
    display: none;
    flex-basis: 70%;
    list-style: none;
    height: auto;
    position: relative;
    justify-content: flex-end;
    margin-top: 10px;
    // background: blue;
    gap: 0.5rem;

    li{
      height: 28px;
      position: relative;
      // background: pink;
    }
    
    li a {
      color: #777;
      font-size: var(--bx-font-size-regular-0);
      letter-spacing: 0.5px;
      // font-family: var( --bx-font-family-primary-lato-bold );
      padding: 0.5rem 0;
      position: relative;

      -webkit-transition: all ease-in-out 0.2s;
      -moz-transition: all ease-in-out 0.2s;
      -o-transition: all ease-in-out 0.2s;
      transition: all ease-in-out 0.2s;
    }

    li:before {
      content: '';
      margin: 0 auto;
      display: block;
      width: 100%;
      height: 2px;
      background: #777;
      transform-origin: right bottom;
      position: absolute;
      bottom: 0; left: 0;
      transform: scaleX(0);
      -webkit-transition: transform 0.3s ease-in-out 0s;
      -moz-transition: transform 0.3s ease-in-out 0s;
      -o-transition: transform 0.3s ease-in-out 0s;
      transition: transform 0.3s ease-in-out 0s;
    }
    li.active a{
      color: #222;
      font-family: var( --bx-font-family-primary-lato-bold );
    }
    li.active:before {         
      transform-origin: left bottom;
      background: #222;   
      transform: scaleX(0.5);     
    }
    li.active:before {      
      transform: scaleX(0.5);     
    }
    li:hover:before {      
      transform: scaleX(1);
      transform-origin: left bottom;

      webkit-transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1) 0s;
      -moz-transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1) 0s;
      -ms-transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1) 0s;
      transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1) 0s;      
    }
    li:hover a {
      color: #111;
    }
  }
  .user-menu {
    display: none;
    flex-basis: clamp(80px, 15%, 350px);
    height: 50px;
    // background: brown;
    position: relative;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;

    .control-center-btn {
      // background: pink;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      gap: 5px;

      .icon {
        // background: yellow;
        width: 17px; height: auto;
        svg { 
          width: 100%; fill: #aaa;
        }
      }
      .title {
        color: #aaa;
        margin-top: -2px;
        // font-size: var( --bx-font-size-regular );
      }
    }

    .user-profile-btn {
      width: 35px; height: 35px;
      border-radius: 30px; border: 2px solid #ccc;
      overflow: hidden;
    }

    .dropdown-btn {
      width: 20px; height: auto;
      background: blue;
    }
  }

  @media all and (min-width: 768px) {
    .main-menu {
      display: flex;
      flex-basis: 70%;
  
      li a {
        // font-size: var(--bx-font-size-regular-0);
      }
    }
    .user-menu {
      flex-basis: clamp(80px, 15%, 350px);
      height: 50px;
      display: flex;
      // gap: 5px;
  
      .control-center-btn {
        flex: 1;
        // gap: 5px;
  
        .icon {
          // width: 20px; height: auto;
          svg {  }
        }
        .title { }
      }
  
      .user-profile-btn {
        width: 35px; height: 35px;
        border-radius: 30px; border: 2px solid #ccc;
      }
  
      .dropdown-btn {
        width: 20px; height: auto;
      }
    }
  }
}
`;

function MainHeader() {

  const dispatch = useDispatch();

  const locationArr = useGetPathNameArr();

  // console.log('location: ', locationArr);

  // function to handle openAppsControlCenter
  function openAppsControlCenter() {
    // first set the control center screen
    dispatch( updateActiveScreen( 'apps' ) );
    // then open it
    dispatch( updateIsVisible( true ) );    
  }

  return (
    <MainHeaderWrapper aria-label='Main Navigation' role="navigation">
      <div className="container">        
        <Link className='main-logo' to="/"><BxLogoH /></Link>
        <ul className='main-menu navigation-item-group'>
          <li className={ `nav-item ${ (locationArr[0] === "" || locationArr.length === 0) && "active" }` }><Link to="/">Home</Link></li>
          <li className={ `nav-item ${ locationArr[0] === "shop" && "active" }` }><Link to="/shop">Shop</Link></li>
          <li className={ `nav-item ${ locationArr[0] === "plans" && "active" }` }><Link to="/plans">Plans</Link></li>
          <li className={ `nav-item ${ locationArr[0] === "contact-us" && "active" }` }><Link to="/contact-us">Contact us</Link></li>
        </ul>
        <div className='user-menu'>
          <button className='control-center-btn' onClick={ openAppsControlCenter }>
              <span className='icon'><SvgIcons name="apps" /></span>
              <span className='title'>Apps</span>
          </button>
          <Link to="/" className='user-profile-btn'><img src="images/general/bodechris.jpg" alt="user profile photo" loading="lazy" /></Link>
          {/* <button className="dropdown-btn"><IoIosArrowDown /></button> */}
        </div>
      </div>
    </MainHeaderWrapper>
  )
}

export default MainHeader;