import React from 'react';
import styled from 'styled-components';
import { ReactComponent as WavyLinesSvg } from "assets/svgs/Wave2.svg";
import { ReactComponent as Strips2 } from "assets/svgs/strips/strip-lines-2.svg";

const MainAppBg = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0; left: 0;
  background: var(--bx-color-white);
  z-index: -1;

  svg.wavyLines {
    margin: 0 auto;
    width: 200%;
    height: 180vh;
    display: block;
    position: absolute;
    top: -40%; left: -50%;
    stroke: #28282833;
    -webkit-transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    -moz-transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    -ms-transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    // -webkit-transition: all ease-in-out 0.2s;
    // -moz-transition: all ease-in-out 0.2s;
    // -o-transition: all ease-in-out 0.2s;
    // transition: all ease-in-out 0.2s;
  }

  .strips-2 {
    margin: 0 auto;
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0; left: 0;
    transform-origin: center center;
    transform: scale(1.5) translate(5%, -10%);
  }

  @media screen and (max-width: 768px) {
    svg.wavyLines {
      width: 500%;
      top: -40%; left: -280%;
      -webkit-transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      -moz-transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      -ms-transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      // -webkit-transition: all ease-in-out 0.2s;
      // -moz-transition: all ease-in-out 0.2s;
      // -o-transition: all ease-in-out 0.2s;
      // transition: all ease-in-out 0.2s;
    }
  }
`;

export default function MainAppBackground( { children } ) {
  return (
    <MainAppBg>
        <WavyLinesSvg className='wavyLines' />
        <Strips2 className='strips-2' />
    </MainAppBg>
  )
}
