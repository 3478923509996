import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ChakraProvider } from '@chakra-ui/react';

import { Provider } from 'react-redux';
import store from './redux/store';



import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#f7f7f7",
      900: "#ffed96",
      800: "#333333",
      500: "#222222",
    },
  },

  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'bold', // Normally, it is "semibold"
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      // 3. We can add a new visual variant
      variants: {
        // 'with-shadow': {
        //   bg: 'red.400',
        //   boxShadow: '0 0 2px 2px #efdfde',
        // },
        // 4. We can override existing variants
        solid: (props) => ({
          // bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
        }),
        // 5. We can add responsive variants
        // sm: {
        //   bg: 'teal.500',
        //   fontSize: 'md',
        // },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        // size: 'lg', // default is md
        // variant: 'sm', // default is solid
        // colorScheme: 'green', // default is gray
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <QueryClientProvider client={ queryClient }>
      <Provider store={ store }>
        <ChakraProvider theme={theme}> 
            <App />     
            {/* <ReactQueryDevtools />        */}
        </ChakraProvider>
      </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
